import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vWow from './views/plugins/v-wow/index'

createApp(App).use(router).use(vWow).mount('#app');




