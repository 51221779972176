import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/backoffice/login',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice/home',
    name: 'Home',
    component: () => import('../views/Backoffice/BackofficeHome.vue')
  },
  {
    path: '/backoffice/logoutt',
    name: 'Logout',
    component: () => import('../views/Backoffice/BackofficeLogout.vue')
  },
  {
    path: '/backoffice/backoffice_users',
    name: 'BackofficeUsers',
    component: () => import('../views/Backoffice/BackofficeUsers.vue')
  },
  {
    path: '/backoffice/wysiwyg',
    name: 'Wysiwyg',
    component: () => import('../views/Backoffice/BackofficeWysiwig.vue')
  },
  {
    path: '/backoffice/forgot_password',
    name: 'BackofficeForgotPassword',
    component: () => import('../views/Backoffice/BackofficeForgotPassword.vue')
  },
  {
    path : '/backoffice/backoffice_user_delete',
    name : 'BackofficeUserDelete',
    component : () => import('../views/Backoffice/BackofficeUserDelete.vue')
  },
  {
    path : '/backoffice/backoffice_user_edit',
    name : 'BackofficeUserEdit',
    component : () => import('../views/Backoffice/BackofficeUserEdit.vue')
  },
  {
    path : '/backoffice/backoffice_user_change_password',
    name : 'BackofficeUserChangePassword',
    component : () => import('../views/Backoffice/BackofficeUserChangePassword.vue')
  },
  {
    path : '/backoffice/file_uploader',
    name : 'BackofficeFileUploader',
    component : () => import('../views/Backoffice/BackofficeFileUploader.vue')
  },
  {
    path : '/backoffice/cloudinary_file_uploader',
    name : 'BackofficeCloudinaryFileUploader',
    component : () => import('../views/Backoffice/BackofficeCloudinaryFileUploader.vue')
  },
  {
    path : '/backoffice/s3_file_uploader',
    name : 'BackofficeS3FileUploader',
    component : () => import('../views/Backoffice/BackofficeS3FileUploader.vue')
  },
  {
    path : '/backoffice/payme',
    name : 'BackofficePayme',
    component : () => import('../views/Backoffice/BackofficePayme.vue')
  },
  {
    path : '/backoffice/payme_ty',
    name : 'BackofficePaymeTy',
    component : () => import('../views/Backoffice/BackofficePaymeTy.vue')
  },
  {
    path : '/backoffice/payme_order_delete',
    name : 'BackofficePaymeOrderDelete',
    component : () => import('../views/Backoffice/BackofficePaymeOrderDelete.vue')
  },
  {
    path : '/backoffice/payme_order_refund',
    name : 'BackofficePaymeOrderRefund',
    component : () => import('../views/Backoffice/BackofficePaymeOrderRefund.vue')
  },
  {
    path : '/backoffice/payme_order_ty',
    name : 'BackofficePaymeOrderTy',
    component : () => import('../views/Backoffice/BackofficePaymeOrderTy.vue')
  },
  {
    path: '/',
    component : () => import('../views/Hp.vue')
  },
  {
    path: '/he',
    component : () => import('../views/He.vue')
  },
  {
    path: '/en',
    component : () => import('../views/En.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    component : () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
